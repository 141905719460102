import { CardContent, Typography, Grid, IconButton, Tooltip } from "@material-ui/core";
import ThreePIcon from '@mui/icons-material/ThreeP';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.1rem',
    marginRight: theme.spacing(0.5),
    color: '#003865'
  },
  nameWithMargin: {
    marginRight: theme.spacing(.75), 
  },
  iconButton: {
    color: 'grey',
    '&:hover': {
      color: '#51a8ff',
    },
  }
}));

const getFirstRecordWithEmail = (roles, roleName) => {
  const role = roles.find(r => r.role === roleName);
  return role ? { name: role.name, email: role.email } : { name: '--', email: '' };
};

const ProjectTeamTable = ({ projectData, selectedProject }) => {
  const projectNumber = selectedProject?.project_number
  const arcoID = selectedProject?.arco_id
  const projectName = selectedProject?.name
  const classes = useStyles();
  const roles = projectData?.project_data?.project_roles || [];

  const renderRole = (label, roleName) => {
    const { name, email } = getFirstRecordWithEmail(roles, roleName);
    return (
      <>
        <span className={classes.label}>{label}:</span>
        <span className={classes.nameWithMargin}>{name}</span>
        {email && (
          <>
            <Tooltip title={`Teams ${name}`}>
              <a href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`} target="_blank" rel="noopener noreferrer">
                <IconButton size="small" className={classes.iconButton}>
                  <ThreePIcon />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title={`Email ${name}`}>
            <a href={`mailto:${email}?subject=Question on ${projectNumber} ${projectName} | ARCO ID: ${arcoID}`} target="_blank" rel="noopener noreferrer">
            <IconButton size="small" className={classes.iconButton}>
                  <EmailIcon />
                </IconButton>
              </a>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  return (
    <CardContent>
      {projectData && Object.keys(projectData).length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className='project-team-typography'>
              {renderRole('PM', 'Project Manager')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('Super', 'Superintendent')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('PC', 'Project Coordinator')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className='project-team-typography'>
              {renderRole('Project Exec', 'Project Executive')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('Gen Super', 'General Superintendent')}
            </Typography>
            <Typography className='project-team-typography'>
              {renderRole('Accountant', 'Project Accountant')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">Please select a project</Typography>
        </div>
      )}
    </CardContent>
  );
};

export default ProjectTeamTable;