import React, {useState, useEffect} from 'react';
import amiLogo from '../static/amiLogo.png';
import '../../project_dashboard/static/project-dashboard-styles.css';
import IconInfo from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Grid, Card, CardContent, Typography, Link, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, DialogTitle, Button } from '@mui/material';
import { Container } from 'react-bootstrap';


import HeaderComponent from '../Components/HeaderComponent';
import ProjectTeamTable from '../Components/ProjectTeamComponent';
import ProjectStageCard from '../Components/ProjectStatusComponent';
import TotalOwnerContractValue from '../Components/OwnerContractSummaryComponent';
import StartDateCard from '../Components/StartDateComponent';
import TCODateCard from '../Components/TCODateComponent';
import ProjectedEndDateCard from '../Components/ProjectedEndDate';
import ProjectTypeCard from '../Components/ProjectTypeComponent';
import SquareFootageCard from '../Components/SquareFootageComponent';
import PriceSFCard from '../Components/PricePerSFComponent';
import ProjectGrade from '../Components/ProjectGradeComponent';
import PercentComplete from '../Components/PercentCompleteComponent';
import ProgressTrackerTable from '../Components/ProgressTrackerComponent';
import OverUnderSummaryChart from '../Components/OverUnderComponent';
import PCOTypeSummary from '../Components/PCOTypeSummaryComponent';
import OwnerBillingsTable from '../Components/PaymentHistoryComponent';
import ProjectExternalLinks from '../Components/ProjectLinksComponent';
import CFASplit from '../Components/CFASplitComponent';
import KeyInfoTable from '../Components/KeyProjectInformationComponent';
import ProjectLocationMap from '../Components/ProjectLocationComponent';
import InfoIconButton from '../Components/InfoIconButtonComponent';
import TablePopoutButton from '../Components/TablePopoutIconComponent';
import BudgetSnapshotSelector from '../Components/ProjectSnapshotSelector';
import ProjectSettingsModal from '../Components/ProjectSettingsButton';
import ContractStrutureChart from '../Components/ContractStructureComponent';
import ProfitPercentage from '../Components/CurrentProfitComponent';
import MasterProjectInfoCard from '../Components/MasterProjectInformationCard';
import MasterContractInfoCard from '../Components/MasterContractInformationCard';
import PercentCompleteBars from '../Components/PercentageCompleteBarsComponent';
import { format } from 'date-fns';
import ProfitDataChart from '../Components/ProfitChartComponent';
import FooterComponent from '../Components/FooterComponent';
import ARCOContingencyChart from '../Components/ArcoContingencyComponent';
import CostsVsBillingsChart from '../Components/CostsAndBillingsComponent';

// Establish Today's Date and Date Options
const date_options = {
    weeday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}
const today_date = new Date().toLocaleDateString("en-US", date_options);
  

const ProjectDashboard = ({ projects, companies, csrfToken, HUB_API_DOMAIN_NAME, apiKey, apiHeader, gmapsAPI }) => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [options, setOptions] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [progress, setProgress] = useState(75);
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [projectData, setProjectData] = useState({});
    const [contractBoxShadowColor, setContractBoxShadowColor] = useState('rgba(0, 0, 0, 0.10)');
    const [stageBoxShadowColor, setStageBoxShadowColor] = useState('rgba(0, 0, 0, 0.10)');
    const [budgetData, setBudgetData] = useState([])
    const [projectSnapshots, setProjectSnapshots] = useState({})
    const [sourceProcore, setSourceProcore] = useState('procore')
    const [selectedSnapshots, setSelectedSnapshots] = useState([]);
    const [changedSnapshots, setChangedSnapshots] = useState(null);
    const [initialSnapshots, setInitialSnapshots] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);

    // Selection Handler for Selection Snapshots    
    const handleSelectionChange = (updatedSnapshots) => {
        setSelectedSnapshots(updatedSnapshots);
        console.log(":::::: UPDATED SNAPSHOTS ::::::::");
        console.log(updatedSnapshots);
    };

    const compareSnapshots = (initialSnapshots, selectedSnapshots) => {
        // Compare Strings    
        // console.log("comparing snapshots")
        // console.log("initial")
        // console.log(initialSnapshots)
        // console.log("selected")
        // console.log(selectedSnapshots)

        console.log("Snapshots Different??")
        return JSON.stringify(initialSnapshots) !== JSON.stringify(selectedSnapshots);
    };

    // Track snapshot selection settings
    const handleModalClose = async (selectedSnapshots) => {
        console.log("Attempting to push snapshots update....")
        console.log(typeof(selectedSnapshots))
        const snapshotsArray = Object.values(selectedSnapshots).flat();

    let selectedIds = snapshotsArray
        .filter(selectedSnapshot => {
            console.log("Selected Snapshot:", selectedSnapshot); // Log each snapshot object
            return selectedSnapshot.selected === true; // Explicitly check for true
        })
        .map(selectedSnapshot => {
            console.log("Selected Snapshot ID:", selectedSnapshot.id); // Log ID of selected snapshots
            return selectedSnapshot.id;
        });

    console.log("Selected IDs:", selectedIds);

    // TODO: Only send the PATCH request if changes were made (need to capture and copy originals)
    // if (compareSnapshots(initialSnapshots, selectedSnapshots)) {
    if (selectedIds.length > 0) {
            console.log("snapshots found, sending it...")
            try {
                const headers = new Headers({
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Auth': apiHeader, 
                    'api-key': apiKey,  
                });

                const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/save_project_settings/`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        project_id: selectedProjectId,
                        selected_snapshots: selectedIds,
                        csrf_token: csrfToken
                    }),
                    credentials: 'include'
                });

                const data = await response.json();

                if (response.ok) {
                    console.log('Snapshots updated successfully:', data);
                } else {
                    console.error('Error updating snapshots:', data.message);
                }
            } catch (error) {
                console.error('Error making PATCH request:', error);
            }
        } else {
            console.log("Didn't receive updatedSnapshots")
        }
    };

// Controls box shadow color for Owner Contract Summary component
    const handleContractSummaryColorChange = (color) => {
        if (color === 'green') {
            setContractBoxShadowColor('rgba(0, 128, 0, 0.5)')
        } else if (color === 'red') {
            setContractBoxShadowColor('rgba(255, 0, 0, 0.5)')
        } else {
            setContractBoxShadowColor('rgba(0, 0, 0, 0.10)')
        }
    };
    const handleStageColorChange = (color) => {
        setStageBoxShadowColor(color);
    };

// Company Selection 
    const handleCompanySelectChange = (event, newValue) => {
        const selectedCompany = newValue;
        setSelectedCompany(selectedCompany);
        setFilteredProjects([]);
        setSelectedProject(null);
        
        // Trigger project fetch when company changes
        if (selectedCompany) {
            fetchProjectsForCompany(selectedCompany.company);
        }
    };

// Fetching Project based on Company Selection
    const fetchProjectsForCompany = (selectedCompanyName) => {
        // Assume you have csrfToken and API setup similar to PHD.js
        const headers = new Headers({
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            'Auth': apiHeader,
            'api-key': apiKey,
        });
        fetch(HUB_API_DOMAIN_NAME + '/phd/get_projects_and_companies/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                company_filter: selectedCompanyName,
                csrf_token: localStorage.getItem('hub_csrf_token')
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            if (data) {
                setFilteredProjects(data.projects || []);
            } else {
                console.error("Failed to fetch projects from HUB API: ", data.message)
            }
        })
        .catch(error => console.error('Error fetching projects for company:', error));
    };    

    const handleProjectSelectChange = (selectedProjectName) => {
        const project = filteredProjects.find(proj => proj.name === selectedProjectName);
        setSelectedProject(project);

    };

// Fetching Individual Project Data
    useEffect(() => {
        const fetchProjectData = async (selectedProjectId) => {
            // Retrieve Project ID from ProjectSelectComponent
            
            //Fetch Project Data from Hub
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });
            try {
                const response = await fetch(HUB_API_DOMAIN_NAME + '/phd/get_project_data/', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        csrf_token: localStorage.getItem('hub_csrf_token'),
                        project_id: selectedProjectId
                    }),
                    credentials: 'include'
                })

                const project_data = await response.json()
                if (project_data) {
                    setProjectData(project_data)
                } else {
                    console.error("Failed to fetch proj data from HUB API: ")
                }
            } catch(error) {
                console.error('Error fetching project data:', error);
            }
            
        };
        if (selectedProjectId) {
            fetchProjectData(selectedProjectId);
        }
    
    }, [selectedProjectId, csrfToken, apiHeader, apiKey])

// Fetching Budget Information & Snapshots for a specific Project
useEffect(() => {
    if (selectedProjectId) {
        const fetchProjectSettings = async () => {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });

            try {
                const payload = {
                    project_id: selectedProjectId,
                    csrf_token: csrfToken,
                };
                console.log("Payload being sent:", payload);

                const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_settings/`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        project_id: selectedProjectId,
                        csrf_token: csrfToken,
                    }),
                    credentials: 'include',
                });

                const data = await response.json();
                console.log("SNAPSHOT DATA::::")
                console.log(data)
                if (data.snapshots) {
                    const initialSelectedSnapshots = Object.values(data.snapshots)
                        .flat()
                        .filter(snapshot => snapshot.selected);
                    
                    setSelectedSnapshots(initialSelectedSnapshots);
                    setInitialSnapshots(initialSelectedSnapshots);
                } else {
                    console.error("Failed to fetch project settings data from HUB API:", data.message);
                }
            } catch (e) {
                console.error('Error fetching project settings data:', e);
            }
        };

        fetchProjectSettings();
    }
}, [selectedProjectId, csrfToken, apiHeader, apiKey]);

useEffect(() => {
    if (selectedProjectId && selectedSnapshots.length > 0) {
        const { actual_start_date, completion_date } = projectData.project_data || {};
        const formatted_start_date = actual_start_date ? new Date(actual_start_date).toISOString().split('T')[0] : null;
        const formatted_end_date = completion_date ? new Date(completion_date).toISOString().split('T')[0] : null;
        const project_id = selectedProjectId;

        const fetchBudgetData = async () => {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });

            const requestBody = {
                start_date: formatted_start_date,
                end_date: formatted_end_date,
                project_id: project_id,
                selected_snapshots: selectedSnapshots.map(snapshot => snapshot.id),
                csrf_token: csrfToken,
            };

            try {
                const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_budget_data/`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(requestBody),
                    credentials: 'include',
                });

                const data = await response.json();
                if (data) {
                    setBudgetData(data || []);
                } else {
                    console.error("Failed to fetch project budget data from HUB API:", data.message);
                }
            } catch (e) {
                console.error('Error fetching budget data:', e);
            }
        };

        fetchBudgetData();
    }
}, [selectedProjectId, selectedSnapshots, projectData, csrfToken, apiHeader, apiKey]);

// Owner Invoice Data -- READY TO GO BUT CREDS ARE OFF
useEffect(() => {
    if (selectedProjectId) {
        const fetchInvoiceData = async () => {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Auth': apiHeader,
                'api-key': apiKey,
            });

            try {
                const payload = {
                    project_id: selectedProjectId,
                    csrf_token: csrfToken,
                };

                console.log("Payload being sent:", payload);

                const response = await fetch(`${HUB_API_DOMAIN_NAME}/phd/get_project_owner_invoice_data/`, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(payload),
                    credentials: 'include',
                });

                const data = await response.json();
                console.log("INVOICE DATA::::");
                console.log(data);

                if (data.data) {
                    setInvoiceData(data.data); 
                } else {
                    console.error("Failed to fetch invoice data from HUB API:", data.error);
                }
            } catch (e) {
                console.error('Error fetching invoice data:', e);
            }
        };

        fetchInvoiceData();
    }
}, [selectedProjectId, csrfToken, apiHeader, apiKey]);

// PATCH Call to Update Selected Snapshots
// const updateSnapshots = async (updatedSnapshots) => {
//     const headers = new Headers({
//         'Content-Type': 'application/json',
//         'X-CSRFToken': csrfToken,
//         'Auth': apiHeader, 
//         'api-key': apiKey,  
//     });

//     try {
//         const response = await fetch(HUB_API_DOMAIN_NAME + '/phd/update_project_snapshots/', {
//             method: 'PATCH',
//             headers: headers,
//             body: JSON.stringify({
//                 project_id: selectedProjectId,
//                 snapshots: updatedSnapshots,
//                 csrf_token: csrfToken
//             }),
//             credentials: 'include'
//         });

//         const data = await response.json();

//         if (response.ok) {
//             console.log('Snapshots updated successfully:', data);
//         } else {
//             console.error('Error updating snapshots:', data.message);
//         }
//     } catch (error) {
//         console.error('Error making PATCH request:', error);
//     }
// };

    console.log("===== Project Dash Console Checks =====")
    console.log("Project Data")
    console.log(projectData)
    console.log("===== Budget Check =====")
    console.log("Budget Data")
    console.log(budgetData)
    console.log("===== Settings Snapshot Data =====")
    console.log(selectedSnapshots)
    console.log("===== INVOICE DATA =====")
    console.log(invoiceData)

    return (
        <Container style={{ marginTop: '40px' }}>
        <Grid container spacing={1}>
  
          {/* Header */}
          <Grid item xs={12}>
            <HeaderComponent
               selectedProject={selectedProject}
               projectData={projectData}
               today_date={today_date}
               selectedCompany={selectedCompany}
               companies={companies}
               handleCompanySelectChange={handleCompanySelectChange}
               filteredProjects={filteredProjects}
               handleProjectSelectChange={handleProjectSelectChange}
               setSelectedProjectId={setSelectedProjectId}
               csrfToken={csrfToken}
               selectedSnapshots={selectedSnapshots}
               handleSelectionChange={handleSelectionChange}
               handleModalClose={handleModalClose}
               selectedProjectId={selectedProjectId}
         />
          </Grid>


        {/* Column 1 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
                {/* <Grid item xs={6}>
                <Card className="card-content-container">
                <CardContent className="card-content">
                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Company</Typography>
                        <CompanySelect 
                            selectedCompany={selectedCompany}
                            options={companies}
                            handleSelectChange={handleCompanySelectChange}
                        />
                    </CardContent>
                </Card>
                </Grid> */}
                {/* <Grid item xs={6}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project No. & Name</Typography>
                        <ProjectSelect 
                            filteredProjects={filteredProjects}
                            selectedProject={selectedProject}
                            handleProjectSelectChange={handleProjectSelectChange}
                            setSelectedProjectId={setSelectedProjectId}
                            />
                    </CardContent>
                </Card>
                </Grid> */}
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Information Summary</Typography>
                        <InfoIconButton
                            projectData={projectData}
                            source="project-info-summary"
                        />
                    </div>

                            <MasterProjectInfoCard projectData={projectData} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Team</Typography>
                        <InfoIconButton
                            projectData={projectData}
                            source="procore-team"
                            sourceProcore={sourceProcore}
                        />
                    </div>
                    <ProjectTeamTable
                        selectedProject={selectedProject}
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                {/* <Grid item xs={4}>
                <Card className="card-content-container" style={{ height: '125px', display: 'flex', flexDirection: 'column' }}>
                <CardContent className="card-content" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top'}}>Start Date</Typography>
                    <StartDateCard 
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={4}>
                <Card className="card-content-container" style={{ height: '125px' }}>
                <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>TCO Date</Typography>
                    <TCODateCard
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={4}>
                <Card className="card-content-container" style={{ height: '125px' }}>
                <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Projected Finish</Typography>
                    <ProjectedEndDateCard 
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={4}>
                <Card className="card-content-container" style={{ height: '125px' }}>
                <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Project Type</Typography>
                    <ProjectTypeCard
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={4}>
                <Card className="card-content-container" style={{ height: '125px' }}>
                <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Total SF</Typography>
                    <SquareFootageCard
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={4}>
               <Card className="card-content-container" style={{ height: '125px' }}>
                <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Cost/SF</Typography>
                    <PriceSFCard
                        projectData={projectData}
                        />
                    </CardContent>
                </Card>
                </Grid> */}
                <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content" style={{ flex: 1 }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Percent Complete</Typography>
                            <div style={{ height:'210px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <PercentCompleteBars projectData={projectData}
                                budgetData={budgetData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content" style={{ flexGrow: 1 }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>CFA Split</Typography>
                            <div style={{ height: '100%', maxHeight:'210px' }}>
                                <CFASplit projectData={projectData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Location</Typography>
                        <ProjectLocationMap
                            projectData={projectData}
                            gmapsAPI={gmapsAPI}
                        />
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
            </Grid>

        {/* Column 2 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
                <Grid item xs={6} style={{ display: 'none', flexDirection: 'column', alignItems: 'stretch' }}>
                    <Card className="card-content-container" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {/* Vertical box shadow option */}
                    <CardContent className="card-content" style={{ boxShadow: `inset 0 -15px 0 0 ${contractBoxShadowColor}`, display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {/* Horizontal box shadow option */}
                    {/* <CardContent className="card-content" style={{ boxShadow: `inset 20px 0 0 0 ${contractBoxShadowColor}`, minHeight: '100%' }}> */}
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Owner Contract Summary</Typography>
                        <TotalOwnerContractValue
                            projectData={projectData}
                            budgetData={budgetData}
                            onPercentageColorChange={handleContractSummaryColorChange}
                            />
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'none', flexDirection: 'column' }}>
                    <Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <CardContent className="card-content" style={{ boxShadow: `inset 0 -15px 0 0 ${stageBoxShadowColor}`, display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', verticalAlign: 'top' }}>Project Status</Typography>
                        <ProjectStageCard
                            projectData={projectData}
                            onStageColorChange={handleStageColorChange}
                            />
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card className="card-content-container">
                            <CardContent className="card-content">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                        Contract Financial Summary
                                    </Typography>
                                    <InfoIconButton
                                        projectData={projectData}
                                        source="contract-financial-summary"
                                    />
                                </div> 
                                <MasterContractInfoCard
                                    projectData={projectData}
                                    budgetData={budgetData}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                        Contract Structure
                                    </Typography>
                                    <InfoIconButton
                                        projectData={projectData}
                                        source="contract-structure"
                                    />
                                </div>
                                <ContractStrutureChart
                                    budgetData={budgetData}
                                />
                            </CardContent>
                        </Card>
                    </Grid>


                <Grid item xs={12}>
                <Card className="card-content-container">
                <CardContent className="card-content" style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ alignSelf: 'flex-start', marginBottom: '16px' }}>
                                ARCO Contingency
                            </Typography>
                            <div style={{ width: '100%', maxWidth: '600px', height: '400px' }}>
                                <ARCOContingencyChart budgetData={budgetData} />
                            </div>
                        </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Over/Under Summary</Typography>
                            <TablePopoutButton 
                                budgetData={budgetData}
                                tableComponent={OverUnderSummaryChart}
                                title={'Over/Under Summary'}
                            />
                        </div>
                        <OverUnderSummaryChart 
                            budgetData={budgetData}
                            isModal={false}
                        />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content" style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ alignSelf: 'flex-start', marginBottom: '16px' }}>
                                Profit
                            </Typography>
                            <div style={{ width: '100%', maxWidth: '600px', height: '400px' }}>
                                <ProfitDataChart budgetData={budgetData} />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                <Card className="card-content-container">
                <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>PCO Type Summary</Typography>
                    <div className="red-datawarehouse-banner" style={{position: 'relative'}}>
                        In Development
                    </div>
                    <PCOTypeSummary 
                        budgetData={budgetData}
                    /> 
                    </CardContent>
                </Card>
                </Grid>
               
            </Grid>
            </Grid>

        {/* Column 3 */}
            <Grid item xs={12} md={4}>
            <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Card className="card-content-container">
                <CardContent className="card-content">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top', paddingLeft: '12px' }}>Over/Under Billings</Typography>
                    </div>
                    <CostsVsBillingsChart
                        budgetData={budgetData}
                        invoiceData={invoiceData}
                    />
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                        <CardContent className="card-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
                                <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                                    Payment & Invoicing History
                                </Typography>
                                {/* <div className="red-datawarehouse-banner" style={{ position: 'relative', marginTop: '15px' }}>
                                    Awaiting VP Data
                                </div> */}
                                <TablePopoutButton
                                    budgetData={budgetData}
                                    tableComponent={OwnerBillingsTable}
                                    title={'Payment & Invoicing History'}
                                    invoiceData={invoiceData}
                                />
                            </div>
                            <OwnerBillingsTable 
                                isModal={false}
                                invoiceData={invoiceData}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Progress Tracker</Typography>
                        <div className="red-datawarehouse-banner" style={{position: 'relative', marginTop: '15px'}}>
                        In Development
                        </div>
                            <TablePopoutButton 
                                budgetData={budgetData}
                                tableComponent={ProgressTrackerTable}
                                title={'Progress Tracker'}
                            />
                        </div>
                        <ProgressTrackerTable style={{maxHeight: '150px'}}
                            isModal={false}
                        />
                    </CardContent>
                </Card>
                </Grid>

                <Grid item xs={12} style={{display: 'none'}}>
                    <Card className="card-content-container">
                    <CardContent className="card-content">
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Information (if needed)</Typography>
                        <KeyInfoTable 
                            projectData={projectData}
                        />
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>
           
            <Grid item xs={12} style={{display: 'none'}}>
                <Card className="card-content-container">
                    <CardContent className="card-content">
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Grade</Typography>
                    <ProjectGrade 
                        selectedProject={selectedProject}/>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
      </Grid>
      <Grid style={{marginTop: '40px'}}>
        <FooterComponent />   
      </Grid>
      
    </Container>
  );
};
  
  export default ProjectDashboard;