import React from 'react';

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CardContent } from '@mui/material';


const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
}

const useStyles = makeStyles({
    table: {
        minWidth: 500,
        whiteSpace: 'nowrap',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    statusIcon: {
        verticalAlign: 'middle',
        marginRight: '8px',
    },
    red: {
        color: 'red',
    },
    green: {
        color: 'green',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#093d61',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
        whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const calculatePaymentAge = (invoiceDate, payFullDate) => {
    if (!invoiceDate || !payFullDate) {
        return null;
    }
    const invoice = new Date(invoiceDate);
    const paid = new Date(payFullDate);

    if (isNaN(invoice) || isNaN(paid)) {
        return null;
    }

    const timeDiff = paid - invoice;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); 
}



const OwnerBillingsTable = ({ invoiceData, isModal }) => {
    const classes = useStyles();

    if (!invoiceData || invoiceData.length === 0) {
        return <Typography style={{ textAlign: 'center' }}>--</Typography>;
    }

    const sortedInvoiceData = invoiceData.sort((a, b) => new Date(b.invoice_date) - new Date(a.invoice_date));


    return (
        <CardContent>
        <TableContainer className={isModal ? classes.tableContainer : ''} component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
            <Table className={classes.table} aria-label="owner billings table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Invoice Date</StyledTableCell>
                        <StyledTableCell>Date Paid</StyledTableCell>
                        <StyledTableCell>Payment Age</StyledTableCell> 
                        <StyledTableCell>Pay App Description</StyledTableCell>
                        <StyledTableCell>Invoiced</StyledTableCell>
                        <StyledTableCell>Paid</StyledTableCell>
                        <StyledTableCell>Cumulative Paid</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedInvoiceData.map((row, index) => {
                        const paymentAge = row.pay_full_date && row.invoice_date ? calculatePaymentAge(row.invoice_date, row.pay_full_date) : null;
                        return (
                            <TableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    {row.invoice_date ? row.invoice_date : '--'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <FiberManualRecordIcon className={`${classes.statusIcon} ${row.paid > 0 ? classes.green : classes.red}`} />
                                    {row.pay_full_date ? row.pay_full_date : '--'} 
                                </StyledTableCell>
                                <StyledTableCell>
                                    {paymentAge !== null ? (
                                        <Typography className={paymentAge >= 30 ? classes.red : classes.green}>
                                            {paymentAge} days
                                        </Typography>
                                    ) : (
                                        '--'
                                    )} 
                                </StyledTableCell>
                                <StyledTableCell>{row.pay_app_description || '--'}</StyledTableCell>
                                <StyledTableCell>{row.invoiced ? formatDollar(row.invoiced) : '--'}</StyledTableCell>
                                <StyledTableCell>{row.paid ? formatDollar(row.paid) : '--'}</StyledTableCell> 
                                <StyledTableCell>{row.cumulative_paid ? formatDollar(row.cumulative_paid) : '--'}</StyledTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </CardContent>
);
};

export default OwnerBillingsTable;