import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const PercentCompleteBars = ({ projectData, budgetData }) => {
	const [scheduleProgress, setScheduleProgress] = useState('--');
	const [costProgress, setCostProgress] = useState('--');

	useEffect(() => {
		if (!projectData || !projectData.project_data || !projectData.project_data.actual_start_date || !projectData.project_data.completion_date || !budgetData.owner_contact_summary) {
			setScheduleProgress('--');
			setCostProgress('--');
			return;
		}

		const parseDate = (dateStr) => new Date(dateStr);

		const calculateScheduleProgress = () => {
			const startDate = parseDate(projectData.project_data.actual_start_date);
			const completionDate = parseDate(projectData.project_data.completion_date);
			const currentDate = new Date();
			const totalDuration = completionDate - startDate;
			const elapsedDuration = currentDate - startDate;

			return Math.max(0, Math.min(100, (elapsedDuration / totalDuration) * 100));
		};

		const calculateCostProgress = () => {
			const costPercentComplete = budgetData.owner_contact_summary.percent_complete || 0;
			return Math.max(0, Math.min(100, costPercentComplete));
		};

		setScheduleProgress(calculateScheduleProgress());
		setCostProgress(calculateCostProgress());

	}, [projectData, budgetData]);

	const getBarStyle = (progress) => ({
		width: `${Math.round(progress)}%`,
		height: '50px',
		backgroundColor: '#4caf50',
		lineHeight: '30px',
		color: 'white',
		display: 'flex',  
		alignItems: 'center',  
		justifyContent: 'center',
		borderRadius: '5px 5px 5px 5px', 
	});

	return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%', alignItems: 'center', marginTop: '10px' }}>
            {projectData && projectData.project_data && projectData.project_data.actual_start_date && projectData.project_data.completion_date && budgetData && budgetData.owner_contact_summary && budgetData.owner_contact_summary.percent_complete !== undefined ? (
                <>
                    {/* Schedule Progress Bar (by Date) */}
                    <div style={{ display: 'flex', gap: '2px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <div className="progress-bar-container" style={{ width: '65%', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
                            <div className="progress-bar" style={getBarStyle(scheduleProgress)}>
                                <Typography>{typeof scheduleProgress === 'number' ? Math.round(scheduleProgress) : '--'}%</Typography>
                            </div>
                        </div>
                        <Typography style={{ width: '15%', marginLeft: '12px', textAlign: 'left' }}>Schedule</Typography>
                    </div>

                    {/* Cost Progress Bar (Tie in Costs) */}
                    <div style={{ display: 'flex', gap: '2px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <div className="progress-bar-container" style={{ width: '65%', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
                            <div className="progress-bar" style={getBarStyle(costProgress)}>
                                <Typography>{typeof costProgress === 'number' ? Math.round(costProgress) : '--'}%</Typography>
                            </div>
                        </div>
                        <Typography style={{ width: '15%', marginLeft: '12px', textAlign: 'left' }}>Cost</Typography>
                    </div>
                </>
            ) : (
                <Typography style={{ textAlign: 'center' }} className="main-key-project-info-body">--</Typography>
            )}
        </div>
    );
}

export default PercentCompleteBars;
