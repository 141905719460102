import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const PriceSFCard = ({ projectData }) => {

    const pricePerSF = () => {
        if (projectData && projectData.project_data && projectData.project_data.total_contract && projectData.project_data.square_feet) {
            let cost = projectData.project_data.total_contract;
            let sf = projectData.project_data.square_feet;

            if (sf !== 0) { 
                let ppsf = cost / sf;
                return ppsf;
            }
        }
        return null;
    };

    const ppsf = pricePerSF();

return (
    <div>
        {projectData && Object.keys(projectData).length > 0 ? (
            <Typography style={{ textAlign:'center'}} className='ppsf-mini'>
                {formatDollar(ppsf)}/SF
            </Typography>
        ) : (
            <Typography style={{textAlign: 'center'}} className="main-key-project-info-body">--</Typography>
        )}
    </div>
    );
}

export default PriceSFCard;