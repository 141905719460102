import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const SquareFootageCard = ({ projectData }) => {

return (
    <div>
        {projectData && Object.keys(projectData).length > 0 ? (
            <Typography style={{ textAlign:'center'}}variant="body1" className='key-project-info-sf '>
                {formatNumber(projectData.project_data?.square_feet) + " SF" || '--'}</Typography>
        ) : (
            <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">--</Typography>
        )}
    </div>
    );
}

export default SquareFootageCard;