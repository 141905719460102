import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }
};

const ProjectedEndDateCard = ({ projectData }) => {

return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>{formatDate(projectData.project_data?.completion_date) || '--'}</Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default ProjectedEndDateCard;