import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardContent, Typography } from '@mui/material';

const formatDollarShort = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(0)}M`;
  } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}k`;
  } else {
      return new Intl.NumberFormat('en-US', { 
          style: 'currency', 
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0 
      }).format(value);
    }
  }

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0 
    }).format(value);
};

const colorPalette = ['#004080', '#0059b3', '#3399ff', '#EEE5E9', '#c3d8e6'];

const ContractStructureChart = ({ budgetData }) => {

    if (!budgetData || !budgetData.contract_structure || budgetData.contract_structure.length === 0) {
            return (
                <CardContent style={{ height: '200px', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography className="key-project-info-body">--</Typography>
                </CardContent>
            );
        }
    
  
    const { Revenue, Cost } = budgetData.contract_structure;
    const revenueKeys = Object.keys(Revenue);
    const costKeys = Object.keys(Cost)
    // spread operator to add rev and cost keys to a unique array
    const uniqueKeys = Array.from(new Set([...revenueKeys, ...costKeys]));


    const data = [
            {
                category: 'Revenue',
                ...Revenue
            },
            {
                category: 'Cost',
                ...Cost
            }
        ]

    const formatLabel = (key) => {
        return key.replace(/ /g, '\n');
    };

    return(
        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 0 }}>
            <div style={{ height: '200px', width: '100%', maxWidth: '900px', maxHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ResponsiveBar
                data={data}
                keys={uniqueKeys}
                indexBy="category"
                layout="horizontal"
                margin={{ top: 20, right: 130, bottom: 80, left: 120 }}
                padding={0.3}
                borderRadius={1}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={({ id, data }) => colorPalette[uniqueKeys.indexOf(id)]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                label={(d) => formatDollarShort(d.value)}
                labelSkipWidth={100}
                labelSkipHeight={100}
                labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        data: uniqueKeys.slice(0, Math.ceil(uniqueKeys.length / 2)).map((key, index) => ({
                            id: key,
                            label: formatLabel(key),
                            color: colorPalette[index], 
                        })),
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 40,
                        itemsSpacing: 20,
                        itemWidth: 100,
                        itemHeight: 40,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    },
                    {
                        dataFrom: 'keys',
                        data: uniqueKeys.slice(Math.ceil(uniqueKeys.length / 2)).map((key, index) => ({
                            id: key,
                            label: formatLabel(key),
                            color: colorPalette[index + Math.ceil(uniqueKeys.length / 2)],
                        })),
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 70,
                        itemsSpacing: 75,
                        itemWidth: 100,
                        itemHeight: 36,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                theme={{
                    labels: {
                        text: {
                            fontSize: 13.5,
                            fill: '#000000',
                        },
                    },
                    axis: {
                        ticks: {
                            text: {
                                fontSize: 15,
                                fill: '#555555',
                                fontWeight: '500',
                            },
                        },
                        legend: {
                            text: {
                                fontSize: 17,
                                fill: '#333333',
                            },
                        },
                    },
                }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={(d) => (
                    <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
                        <strong>{d.indexValue} - {d.id}</strong>
                        <br />
                        Total: {formatDollar(d.value)}
                    </div>
                )}
            />
        </div>
    </CardContent>
    )
}

export default ContractStructureChart;