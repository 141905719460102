import React from "react";
import { useEffect } from "react";
import '../static/percent-change-styles.css';

import { Card, CardContent, Typography, Box } from "@material-ui/core";

const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
}

const calculatePercentageDifference = (original, current) => {
    if (typeof original === 'number' && typeof current === 'number' && original !== 0) {
        return ((current - original) / original * 100).toFixed(2);
    }
    return '--';
};

const TotalOwnerContractValue = ({ projectData, onPercentageColorChange }) => {
    // Define contract values & calc difference
    const originalContract = projectData.project_data?.total_value
    const totalValue = projectData.project_data?.total_contract
    const percentageDifference = calculatePercentageDifference(originalContract, totalValue)
    const percentageDifferenceColor = percentageDifference !== '--' 
    ? (parseFloat(percentageDifference) > 0 ? 'green' 
        : (parseFloat(percentageDifference) < 0 ? 'red' 
        : 'rgba(0, 0, 0, 0.10)')) 
    : 'rgba(0, 0, 0, 0.10)';

    // Using to send color updates to container
        useEffect(() => {
            if (onPercentageColorChange) {
                onPercentageColorChange(percentageDifferenceColor);
            }
        }, [percentageDifferenceColor, onPercentageColorChange]);

return (
    <CardContent>
            {projectData && projectData.project_data && projectData.project_data.total_contract ? (
                <div style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: '28px', fontFamily: "Titillium Web, sans-serif", fontWeight: '400'}}>
                        {formatDollar(totalValue) || "Missing Conract Value" }
                    </Typography>
                    <div className="percent-change-subcontainer" style={{display: 'none'}}>
                        <Typography style={{ fontSize: '18px', fontFamily: "Titillium Web, sans-serif" }}>
                            Original: {formatDollar(originalContract) || "Missing Initial Contract Value"}
                        </Typography>
                        <Typography style={{ fontSize: '18px', fontFamily: "Titillium Web, sans-serif", color: percentageDifferenceColor}}>
                        ( {percentageDifference}% )
                        </Typography>
                    </div>
                   
                </div>
            ) : (
                    <Typography style={{textAlign: 'center'}}className="key-project-info-body">--</Typography>
            )}
        </CardContent>
    );
}

export default TotalOwnerContractValue;