import React from 'react';

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const useStyles = makeStyles({
    table: {
        minWidth: 500,
        borderRadius: '15px',
        
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#093d61',
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OverUnderSummaryChart = ( {budgetData, isModal} ) => {
    const classes = useStyles();
    const rows = budgetData?.over_under_summary || [];

    return (
        <CardContent >
            { !budgetData.over_under_summary? (
               <Typography style={{textAlign: 'center'}}className="key-project-info-body">--</Typography>
            ) : rows.length === 0 ? (
                <div style={{position: 'relative'}}>
                    No Budget Information Found for this Project.
                </div>
            ) : (
                <TableContainer className={isModal ? classes.tableContainer : ''} component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
                <Table className={classes.table} aria-label="payment history table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >Value</StyledTableCell>
                            <StyledTableCell >Description</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    {formatDollar(row.value)}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.description}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            )}
        </CardContent>
    );
};


export default OverUnderSummaryChart;
