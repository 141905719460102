import { useState, useRef, useEffect } from 'react';
import { Radio, List, ListItem, ListItemText, ListItemSecondaryAction, Typography, Box, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const BudgetSnapshotSelector = ({ projectSnapshots, onSelectionChange }) => {
  const [localSelectedSnapshots, setLocalSelectedSnapshots] = useState({});
  const snapshotRefs = useRef({});

  // Identify the earliest snapshot for the baseline icon
  const earliestSnapshot = projectSnapshots.reduce((earliest, snapshot) => {
    return !earliest || new Date(snapshot.created_at) < new Date(earliest.created_at)
      ? snapshot
      : earliest;
  }, null);

  // Initialize the local state based on the snapshots that are initially marked as selected
  useEffect(() => {
    const initialSelection = {};
    projectSnapshots.forEach((snapshot) => {
      if (snapshot.selected) {
        initialSelection[snapshot.id] = snapshot.id;
      }
    });
    setLocalSelectedSnapshots(initialSelection);
  }, [projectSnapshots]);

  // Handle Radio Selection
  const handleRadioChange = (snapshotId) => {
    // Update local selected snapshot
    setLocalSelectedSnapshots((prev) => ({
      ...prev,
      [snapshotId]: snapshotId,
    }));

    // Update selected status in snapshots array
    const updatedSnapshots = projectSnapshots.map((snapshot) =>
      snapshot.id === snapshotId ? { ...snapshot, selected: true } : { ...snapshot, selected: false }
    );

    // Send updated snapshots back to the parent
    onSelectionChange(updatedSnapshots);
  };

  // Group snapshots by year and month
  const groupSnapshotsByYearAndMonth = (snapshots) => {
    return snapshots.reduce((acc, snapshot) => {
      const snapshotDate = new Date(snapshot.created_at);
      const year = snapshotDate.getFullYear();
      const month = snapshotDate.toLocaleString('default', { month: 'long' });

      if (!acc[year]) {
        acc[year] = {};
      }
      if (!acc[year][month]) {
        acc[year][month] = [];
      }

      acc[year][month].push(snapshot);
      return acc;
    }, {});
  };

  const snapshotsByYearAndMonth = groupSnapshotsByYearAndMonth(projectSnapshots);

  if (projectSnapshots.length === 0) {
    return <div>No budget snapshots available</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Box style={{ position: "sticky", top: 0 }}>
          <Typography variant="h6">Years</Typography>
          <List>
            {Object.keys(snapshotsByYearAndMonth).map((year) => (
              <ListItem
                button
                key={year}
                onClick={() =>
                  snapshotRefs.current[year]?.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                <ListItemText primary={year} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>

      <Grid item xs={10}>
        <Box style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          {Object.keys(snapshotsByYearAndMonth).map((year) => (
            <div key={year} ref={el => snapshotRefs.current[year] = el}>
              <Typography variant="h5" style={{ marginTop: '20px' }}>{year}</Typography>
              {Object.keys(snapshotsByYearAndMonth[year]).map((month) => (
                <div key={month} style={{ paddingLeft: '20px' }}>
                  <Typography variant="h6" style={{ marginTop: '10px' }}>{month}</Typography>
                  <List>
                    {snapshotsByYearAndMonth[year][month].map((snapshot) => (
                      <ListItem key={snapshot.id} button>
                        <ListItemText
                          primary={
                            <>
                              {snapshot.name} (Captured on {new Date(snapshot.created_at).toLocaleDateString('en-US')})
                              {snapshot.id === earliestSnapshot.id && (
                                <span style={{ marginLeft: '10px', color: 'gold' }}>
                                  <StarIcon />
                                </span>
                              )}
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Radio
                            edge="end"
                            checked={localSelectedSnapshots[snapshot.id] === snapshot.id}
                            onChange={() => handleRadioChange(snapshot.id)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
              ))}
            </div>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default BudgetSnapshotSelector;