import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import OverUnderSummaryChart from './OverUnderComponent';

const TablePopoutButton = ({ tableComponent: TableComponent, budgetData, invoiceData, title }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <TableChartIcon />
            </IconButton>
            <Dialog open={openDialog} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogTitle >{title}</DialogTitle>
                <DialogContent style={{ height: '70vh', display: 'flex', flexDirection: 'column' }}>
                    <TableComponent 
                        budgetData={budgetData} 
                        invoiceData={invoiceData}
                        isModal={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TablePopoutButton;
