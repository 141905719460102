import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Link, Typography } from '@mui/material';
import IconInfo from '@mui/icons-material/Info';

const InfoDialogButton = ({ sourceProcore, projectData, source }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        // Confirm projectData is not empty
        if (projectData && Object.keys(projectData).length !== 0) {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    // Can rework/axe this
    let sourceText;
    let projectId;
    let modalConfigURL;
    let helpText;
    let linkName;
    switch (source) {
        case 'procore-team':
            sourceText = 'Project Team information is sourced from Procore.';
            projectId = projectData?.project_data?.procore_id;
            helpText = "You can update your Team's information in the link below:"
            modalConfigURL = `https://app.procore.com/${projectId}/project/directory/configure_tab`;
            linkName = "Open Procore Admin Panel"
            break;
        case 'salesforce':
            sourceText = 'This information is sourced from Salesforce.';
            break;
        case 'project-info-summary':
            sourceText = "This information is sourced from Procore's admin panel.";
            helpText = "You can update your Team's information by following the link below:";
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/${projectId}/project/directory/configure_tab`;
            linkName = "Open Procore Admin Panel"
            break;
        case 'contract-financial-summary':
            sourceText = "This information is pulled from Procore budget snapshots.";
            helpText = "Your can adjust the selected snapshots in the upper right of the header. Or click the link below to update your budget information in Procore.";
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/projects/${projectId}/budgeting`;
            linkName = "Open Procore Budget Tool"
            break;
        case 'contract-structure':
            sourceText = "This information is pulled from budget snapshots and cost code classification in the AMI Budget Variance Tool.";
            helpText = "Please use the latest version of AMI Budget Variance Tool to automatically sync data with Project Health Dashboard. You can adjust the selected snapshots in the upper right of the header. Or click the link below to update your budget information in Procore.";
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/projects/${projectId}/budgeting`;
            linkName = "Open Procore Budget Tool"
            break;
        default:
            sourceText = ''; 
            break;
    }
    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <IconInfo />
            </IconButton>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>{sourceText}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>{helpText}</Typography>
                        <Button 
                        variant="contained" 
                        style={{
                            marginTop: '20px',
                            backgroundColor: '#1976d2',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px', 
                            textTransform: 'none',
                            boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
                            marginTop: '20px',
                            display: 'block',
                            textAlign: 'center',
                          }}id="procore-team-config" href={modalConfigURL} target="_blank" rel="noopener noreferrer">{linkName}</Button>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InfoDialogButton;