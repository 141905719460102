import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Typography } from '@material-ui/core';

const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
};

const formatDollarShort = (value) => {
    const absValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';

    if (absValue >= 1000000) {
        return `${sign}$${(absValue / 1000000).toFixed(2)}M`;
    } else if (absValue >= 100000) {
        return `${sign}$${(absValue / 1000).toFixed(0)}k`;
    } else if (absValue >= 1000) {
        return `${sign}$${(absValue / 1000).toFixed(2)}k`;
    } else {
        return new Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0 
        }).format(value);
    }
};

const CostsVsBillingsChart = ({ invoiceData, budgetData }) => {
    // Extract the latest cumulative_paid from invoiceData
    const latestInvoice = invoiceData && invoiceData.length > 0 
        ? invoiceData.reduce((latest, current) => new Date(current.invoice_date) > new Date(latest.invoice_date) ? current : latest) 
        : null;
    
    const cumulativePaid = latestInvoice ? latestInvoice.cumulative_paid : 0;

    // Extract the JTD costs from profitData
    const jtdCosts = budgetData?.owner_contact_summary?.jtd_costs || 0;

    // Prepare data for the chart
    const chartData = [
        { id: 'Billings', value: cumulativePaid, color: '#083d61' },
        { id: 'Costs', value: jtdCosts, color: '#54bef3'}
    ];

    return (
        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {cumulativePaid === 0 && jtdCosts === 0 ? (
                <Typography style={{ textAlign: 'center' }}>--</Typography>
            ) : (
                <ResponsiveBar
                    data={chartData}
                    keys={['value']}
                    indexBy="id"
                    margin={{ top: 5, right: 50, bottom: 60, left: 120 }}
                    padding={0.4}
                    colors={({ id, data }) => data.color}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    borderRadius={5}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Category',
                        legendPosition: 'middle',
                        legendOffset: 36,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Amount ($)',
                        legendPosition: 'middle',
                        legendOffset: -85,
                        format: value => formatDollarShort(value)
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                    label={(d) => formatDollarShort(d.value)}
                    theme={{
                        labels: {
                            text: {
                                fontSize: 17,
                                fill: '#000000',
                                fontFamily: 'Titillium Web, sans-serif',
                            },
                        },
                        axis: {
                            ticks: {
                                text: {
                                    fontSize: 14,
                                    fill: '#555555',
                                    fontFamily: 'Titillium Web, sans-serif',
                                },
                            },
                            legend: {
                                text: {
                                    fontSize: 17,
                                    fill: '#333333',
                                    fontFamily: 'Titillium Web, sans-serif',
                                },
                            }
                        }
                    }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({ indexValue, value }) => (
                        <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
                            <strong>{indexValue}</strong>
                            <br />
                            Amount: {formatDollar(value)}
                        </div>
                    )}
                />
            )}
        </div>
    );
};

export default CostsVsBillingsChart;
