import React, { useEffect } from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const ProfitPercentage = ({ budgetData }) => {

    // useEffect(() => {
    //     console.log("Budget data received or updated:", budgetData);
    // }, [budgetData]);

    if (!budgetData || !budgetData.profit_data || budgetData.profit_data.length === 0) {
        return '--';
    }
    
    const getProfitPercentage = () => {
        const latestProfitData = budgetData.profit_data[budgetData.profit_data.length - 1];
        if (latestProfitData && latestProfitData.profit_percentage != null) {
            return `${latestProfitData.profit_percentage.toFixed(2)}%`;
        }
        return '--';
    };
return (
    <div>
    <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
        {getProfitPercentage()}
    </Typography>
</div>
    );
}

export default ProfitPercentage;