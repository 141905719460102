import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const ProjectTypeCard = ({ projectData }) => {


return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>{projectData.project_data?.project_type || '--'}</Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default ProjectTypeCard;