import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const ProjectSettingsModal = ({ Component, projectSnapshots, budgetData, title, selectedSnapshots, onSelectionChange, handleModalClose }) => {
    const [openDialog, setOpenDialog] = useState(false);

   const handleClickOpen = () => {
    setOpenDialog(true);
    };

    // Function to close the modal AND trigger the PATCH request to update
    const handleCloseModal = () => {
        handleModalClose(selectedSnapshots);
        setOpenDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <SettingsIcon style={{color: 'white'}} />
            </IconButton>
            <Dialog open={openDialog} onClose={handleCloseModal} maxWidth="xl" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent className='titillium-web-base'>Changes made to these selections will update the data used by the rest of the dashboard.</DialogContent>
                <DialogContent style={{ height: '50vh', display: 'flex', flexDirection: 'column' }}>
                    <Component 
                        budgetData={budgetData} 
                        isModal={true}
                        projectSnapshots={projectSnapshots}
                        selectedSnapshots={selectedSnapshots}
                        onSelectionChange={onSelectionChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProjectSettingsModal;
