import {useState, useEffect} from "react";
import bonusToolIcon from '../static/bonus_tool_icon.svg';
import "../static/project-dashboard-styles.css"

import { Typography, CardContent, Link } from "@mui/material";
import ProjectExternalLinks from "./ProjectLinksComponent";
import ProjectSelect from "./ProjectSelectComponent";
import CompanySelect from "./CompanySelectorComponent";
import BudgetSnapshotSelector from "./ProjectSnapshotSelector";
import ProjectSettingsModal from "./ProjectSettingsButton";

const HeaderComponent = ({ 
    selectedProject,
    projectData,
    today_date,
    selectedCompany,
    companies,
    handleCompanySelectChange,
    filteredProjects,
    handleProjectSelectChange,
    setSelectedProjectId,
    csrfToken, 
    selectedSnapshots,
    handleSelectionChange,
    handleModalClose,
    selectedProjectId
    }) => {
    
    // Latest Snapshot for Data Updated
    const [mostRecentCreatedAt, setMostRecentCreatedAt] = useState(null);

    useEffect(() => {
        if (selectedSnapshots && selectedSnapshots.length > 0) {
            const mostRecent = selectedSnapshots.reduce((latest, snapshot) => {
                return new Date(snapshot.created_at) > new Date(latest) ? snapshot.created_at : latest;
            }, selectedSnapshots[0].created_at);

            setMostRecentCreatedAt(new Date(mostRecent).toLocaleDateString());
        } else {
            setMostRecentCreatedAt(null);
        }
    }, [selectedSnapshots]);

    
    
    return (
        <CardContent className="title-block-content">
            <div className="dashboard-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '10px' }}>
                <div className="dashboard-selectors-container" style={{ display: 'flex', flex: 1, gap: '20px', alignItems: 'flex-start' }}>
                    <div className='dashboard-selector' style={{ flex: 1 }}>
                        <CompanySelect
                            selectedCompany={selectedCompany}
                            options={companies}
                            handleSelectChange={handleCompanySelectChange}
                        />
                    </div>
                    <div className='dashboard-selector' style={{ flex: 1 }}>
                        <ProjectSelect
                            filteredProjects={filteredProjects}
                            selectedProject={selectedProject}
                            handleProjectSelectChange={handleProjectSelectChange}
                            setSelectedProjectId={setSelectedProjectId}
                            csrfToken={csrfToken}
                        />
                    </div>
                </div>
                {/* <div className="navigation-links titillium-web-base">
                    <Link className="nav-link">Project Health</Link>
                    <Link className="nav-link">Company Health</Link>
                    <Link className="nav-link">Operations</Link>
                </div> */}
                        {/* <img src={amiLogo} alt="AMI logo" style={{width: "50px"}}/> */}
            </div>
                {selectedProject ? (
                                <div className='project-information-container'>
                                    <div className='project-account-container'>
                                        <Typography variant='h5' className="titillium-web-base">{selectedProject.name}</Typography>
                                        <Typography variant='h5' className="titillium-web-base">({projectData.project_data?.account_name})</Typography>
                                    </div>
                                    <Typography variant='h5' className="titillium-web-base">ARCO ID: {projectData.project_data?.arco_id}</Typography>
                             </div>
                            ) : (
                                <div className='project-information-container' style={{marginBottom: '20px'}}>
                                <Typography variant='h6' className="titillium-web-base" style={{ marginTop: '20px', textAlign: 'center' }}>
                                    Please Select a Company and Project to View Data.
                                </Typography>
                                </div>
                            )
                        }
                
                <div className="project-links-container" style={{ flex: 1 }}>
                    {selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && mostRecentCreatedAt && (
                        <Typography variant='h6' className="titillium-web-base" >Latest Budget Snapshot: {mostRecentCreatedAt} </Typography>
                    )}
                 
                    {selectedProject && 
                        <div className="bonus-tool-container" style={{display: 'none'}}>
                            <Typography variant='h6' className="titillium-web-base">
                            Bonus Model
                            </Typography>
                            <Link href='#' target="_blank" variant='h6' className="titillium-web-base">
                            <img src={bonusToolIcon} className="bonus-tool-icon"/>
                            </Link>
                        </div> 
                    }     
                  <ProjectExternalLinks
                    selectedProjectId={selectedProjectId}
                    projectData={projectData}
                    />

                {selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography variant="subtitle1" className="card-title" style={{ color: 'white', textAlign: 'left', verticalAlign: 'top' }}>
                            Budget Snapshot Selector
                        </Typography>
                            <ProjectSettingsModal
                                Component={BudgetSnapshotSelector}
                                projectSnapshots={selectedSnapshots}
                                title={'Budget Snapshot Selector'}
                                selectedSnapshots={selectedSnapshots}
                                onSelectionChange={handleSelectionChange}
                                handleModalClose={handleModalClose}
                            />
                       
                    </div>
                )}
            </div>
        </CardContent>
    );
};

export default HeaderComponent;