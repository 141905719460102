import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';


const formatDollar = (value) => {
    const roundedValue = Math.ceil(value);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(roundedValue);
}

const formatDollarShort = (value) => {
    const absValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';

    if (absValue >= 1000000) {
        return `${sign}$${(absValue / 1000000).toFixed(2)}M`;
    } else if (absValue >= 100000) {
        return `${sign}$${(absValue / 1000).toFixed(0)}k`;
    } else if (absValue >= 1000) {
        return `${sign}$${(absValue / 1000).toFixed(2)}k`;
    } else {
        return new Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0 
        }).format(value);
    }
};

const ProfitDataChart = ({ budgetData }) => {

    // Check if budgetData is loaded and has profit_data
    if (!budgetData || !budgetData.profit_data || budgetData.profit_data.length === 0) {
        return <Typography style={{ textAlign: 'center' }}>--</Typography>;
    }

    const getTickValues = (budgetData, interval) => {
        if (!budgetData || !budgetData.profit_data || budgetData.profit_data.length === 0) return [];
    
        // If there are 10 or fewer items, return all dates
        if (budgetData.profit_data.length <= 10) {
            return budgetData.profit_data.map(item => item.snapshot_date);
        }
    
        // Otherwise, return every other tick value (or adjust per interval)
        return budgetData.profit_data
            .map((item, index) => (index % interval === 0 ? item.snapshot_date : null))
            .filter(Boolean);
    };

    // Prepare data for Nivo Bar Chart
    const chartData = budgetData.profit_data.map(item => ({
        snapshot_id: item.snapshot_date.toString(),
        profit: item.profit,
        profit_percentage: item.profit_percentage,
    }))
    .sort((a, b) => new Date(a.snapshot_id) - new Date(b.snapshot_id));

    return (
        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ResponsiveBar
                data={chartData}
                keys={['profit']}
                indexBy="snapshot_id"
                margin={{ top: 5, right: 30, bottom: 80, left: 100 }}
                padding={0.4}
                colors={['#083d61']}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                borderRadius={2}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Snapshot Date',
                    legendPosition: 'middle',
                    legendOffset: 65,
                    tickValues: getTickValues(budgetData, 2)
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Profit ($)',
                    legendPosition: 'middle',
                    legendOffset: -90,
                    format: value => formatDollarShort(value)
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                theme={{
                    labels: {
                      text: {
                        fontSize: 13.5,
                        fill: '#000000',
                        fontFamily: 'Titillium Web, sans-serif',
                      },
                    },
                    axis: {
                      ticks: {
                        text: {
                          fontSize: 12, 
                          fill: '#555555',
                          fontFamily: 'Titillium Web, sans-serif', 
                        },
                      },
                      legend: {
                        text: {
                          fontSize: 17,
                          fill: '#333333',
                          fontFamily: 'Titillium Web, sans-serif',
                        },
                      }
                    },
                }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                label={(d) => formatDollarShort(d.value)}
                tooltip={({ indexValue, value, data }) => (
                    <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
                        <strong>Date: {indexValue}</strong>
                        <br />
                        Profit: {formatDollar(value)}
                        <br />
                        Profit Percentage: {data.profit_percentage ? data.profit_percentage.toFixed(2) : '--'}%
                    </div>
                )}
            />
        </div>
    );
};

export default ProfitDataChart;
