import React, { useEffect, useState } from 'react';
import { HUB_URL } from '../../utils/config/config';
import ProjectDashboard from '../../components/project_dashboard/Containers/project_dashboard';

// Dev Note: This component is primarily used for authentication and initial broad data fetching. All subsequent API fetches for specific projects and project data is to be conducted in the ProjectDashboard container and the individual components rendered within it.

const API_AUTH_HEADER = process.env.REACT_APP_API_AUTH_HEADER
const API_KEY = process.env.REACT_APP_API_KEY
const HUB_API_DOMAIN_NAME = process.env.REACT_APP_HUB_API_DOMAIN_NAME
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

const PHD = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [csrfToken, setCsrfToken] = useState(null);
    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        // Send message to parent to establish trust
        console.log('Sending message to parent....')
        window.parent.postMessage(window.location.origin,HUB_URL);
    }, []);

    // Listen for messages from parent
    window.addEventListener("message", function(event) {
        // Ensure message is from trusted parent domain
        console.log('Event Listener setup....',event.origin, HUB_URL)
        if (event.origin === HUB_URL) {
            console.log('EVENT ORIGIN CHECK PASS')
            const token = event.data.token;
            setCsrfToken(token);
            console.log('TOKEN SET IN STATE:',token)
        }
    });

    // Use Effect to Pull Initial Companies
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const user_uid = urlParams.get('user_uid');
        console.log('USE EFFECT....',csrfToken, user_uid)

        // once the csrfToken is received, we start the API handshake.
        if (csrfToken && user_uid) {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.set('X-CSRFToken', csrfToken);
            headers.append('Auth', API_AUTH_HEADER);
            headers.append('api-key', API_KEY);

            console.log('setting csrf token in local storage....',csrfToken)
            localStorage.setItem('hub_csrf_token', csrfToken);
            console.log('done setting csrf token in local storage....')

            fetch(HUB_URL + '/phd/auth_check/', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ user_uid: user_uid, csrf_token: localStorage.getItem('hub_csrf_token')}),
                credentials: 'include'
            })
                .then(response => response.json())
                .then(data => {
                    if (data.auth_check_passed === true) {
                        setAuthenticated(true);

                        // At this point, authentication is successful.
                        // Set all logic after this only.
                        
                        // SAMPLE API CALL: Pulls active projects and companies to populate dropdown in PHD UI. 
                        fetch(HUB_URL + '/phd/get_projects_and_companies/', {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify({ csrf_token: localStorage.getItem('hub_csrf_token'), 'project_id':6147}),
                            credentials: 'include'
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setProjects(data.projects || []);
                                setCompanies(data.companies || []);
                                // console.log("===== PROJECT & COMPANY INFO=====")
                                // console.log(projects)
                            } else {
                                console.error("Failed setting Projects and Companies:", data)
                            }
                            });   
                    } else {
                        console.error('Token validation failed:', data);
                    }
                });
        }
    }, [csrfToken]);
    

    if (!authenticated) {
        console.log('NOT AUTH......')
        return (
            <div>
                <div>Loading...</div>
            </div>
        );
    }
    console.log('AUTH......')

    return <div>
            <ProjectDashboard 
                companies={companies} 
                projects={projects}
                csrfToken={csrfToken} 
                HUB_API_DOMAIN_NAME={HUB_API_DOMAIN_NAME}
                apiKey={API_KEY}
                apiHeader={API_AUTH_HEADER}
                gmapsAPI={GOOGLE_API_KEY}
            />
        </div>;
};

export default PHD;


